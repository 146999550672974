import * as React from 'react'
import { t } from 'ttag'
import {observer} from 'mobx-react'
import {action} from 'mobx'
import {FormGroup, Label, FormText, Input, Row, Col} from 'reactstrap'
import {Upload, UppyWrapper} from '../Forms/Upload'
import {FieldState, FormState} from 'formstate'
import {FieldFeedBack} from '../Forms/FieldFeedBack'
import {PassengerFormState, IDDocumentFormState} from './state'

interface SubmitterUploadsProps {
    passenger: PassengerFormState
    extra?: boolean
    className?: string
    makeIDDocumentFormState: () => IDDocumentFormState
}


// TODO: Do we really need a separate class for submitter uploads instead of using PassengerUploads?


@observer
export default class SubmitterUploads extends React.Component<SubmitterUploadsProps> {
    
    onIsPassportSelectedChange(value: boolean) {
        if (value) {
            this.props.passenger.$.idDoc.$.splice(1)
        } else {
            this.props.passenger.$.idDoc.$.splice(
                this.props.passenger.$.idDoc.$.length - 1, 0, this.props.makeIDDocumentFormState()
            )  
        }

        this.props.passenger.$.isPassportSelected.onChange(value)
    }

    onUploadSuccess = (field: FieldState<Upload>, file: Upload) => {
        field.onChange(file)
        field.validate()
    }

    render () {
        const fields = this.props.passenger.$

        return <div>
            <FormGroup>
                <Label className="mb-0 mt-2">{t`Boarding pass or ticket`}</Label>

                <FormText className="mt-1 mb-2 d-block">
                    {t`
                        This can be a photo (if you had printed boarding passes) or electronic 
                        documents you have received from your airline or travel agency
                    `}
                </FormText>

                { fields.boardingPassDoc.value ?
                    <div key={fields.boardingPassDoc.value.id}>{fields.boardingPassDoc.value.name}</div>
                    :
                    null
                }

                <UppyWrapper onUploadSuccess={(file) => this.onUploadSuccess(fields.boardingPassDoc, file)}/>

                <FieldFeedBack field={fields.boardingPassDoc}/>
            </FormGroup>

            <FormGroup>
                <Label className="mb-0 mt-2">{t`Photo of passport or ID card`}</Label>

                <FormText className="mt-1 mb-2 d-block">
                    {t`
                        Most airlines require a photo or scan of your ID document as proof that
                        we are indeed representing you when claiming compensation.
                    `}
                </FormText>

                <p className="mt-3">
                    {t`What document would you like to provide?`}
                </p>

                <FormGroup>
                    <FormGroup check>
                        <Label check>
                            <Input
                                type="radio"
                                name="is_passport_selected"
                                value={'aye'}
                                checked={this.props.passenger.$.isPassportSelected.value}
                                onChange={action(() => this.onIsPassportSelectedChange(true))}
                            />
                            {' '} {t`Passport`}
                        </Label>
                    </FormGroup>

                    <FormGroup check>
                        <Label check>
                            <Input
                                type="radio"
                                name="is_passport_selected"
                                value={''}
                                checked={!this.props.passenger.$.isPassportSelected.value}
                                onChange={action(() => this.onIsPassportSelectedChange(false))}
                            />
                            {' '} {t`ID card`}
                        </Label>
                    </FormGroup>
                </FormGroup>

                { fields.idDoc.$.length > 1 ?
                    <Row>
                        <Col md={12}>
                            {t`Photos of both the front and the back of the card are required:`}
                        </Col>
                    </Row> : null
                }

                <Row>

                    {
                        fields.idDoc.$.map((document, key) => {
                            return <Col key={key} md={fields.idDoc.$.length > 1 ? 6 : 12}>
                                {
                                    document.$.idDocSide.value ?
                                    <div key={document.$.idDocSide.value.id}>
                                        {document.$.idDocSide.value.name}
                                    </div>
                                    :
                                    null
                                }
                                <UppyWrapper
                                    onUploadSuccess={(file) => this.onUploadSuccess(
                                        document.$.idDocSide, file
                                    )}
                                />
                                <FieldFeedBack field={document.$.idDocSide}/>
                            </Col>
                        })
                    }
                </Row>

            </FormGroup>
            
        </div>
    }
}
