import * as React from 'react'
import { t } from 'ttag'
import autobind from 'autobind-decorator'
import {observer} from 'mobx-react'
import {getStateInstance} from './state'
import {Form, FormGroup, FormText, Label, Input, Button} from 'reactstrap'
import {FieldFeedBack} from '../Forms/FieldFeedBack'
import { navigate } from '../../util/navigate'
import SubmitterUploads from './SubmitterUploads'
import {getLanguageCode} from '../../locales'


interface Props {
    location: Location
}

@observer
export class SubmitterItineraryForm extends React.Component<Props> {
    render () {
        let pageState = getStateInstance()
        let formState = pageState.formState.submitterItinerary
        let fields = formState.$

        return <Form onSubmit={this.onSubmit}>
            <h1>{t`Flight itinerary`}</h1>

            <p>
                {t`
                    We have almost finished. We only need 2 documents from you. This
                    is what airlines ask as a proof that you were indeed entitled to fly on
                    this particular flight and that Refundor is claiming compensation on
                    your behalf.
                `}
            </p>

            <FormGroup>
                <a ref={fields.bookingNumber.positionRef as any}/>
                <Label for="booking-number">{t`Your booking number`}</Label>
                <Input
                    type="text"
                    name="booking-number"
                    invalid={!!fields.bookingNumber.error}
                    value={fields.bookingNumber.value || ''}
                    onChange={(e) => fields.bookingNumber.onChange(e.target.value)}
                    onBlur={() => fields.bookingNumber.validate()}
                />
                <FieldFeedBack field={fields.bookingNumber}/>
                <FormText>{t`This will usually consist of 5-6 letters and numbers`}</FormText>
            </FormGroup>

            <SubmitterUploads
                makeIDDocumentFormState={() => pageState.makeIDDocumentFormState()}
                passenger={pageState.formState.submitter}
            />

            <Button className="submit mt-5" block color="primary">{t`Continue`}</Button>

        </Form>
    }

    @autobind
    async onSubmit (e) {
        e.preventDefault()

        let pageState = getStateInstance()
        let validationResult = await pageState.formState.submitterItinerary.validate()

        if (!validationResult.hasError) {
            navigate('/claim-form/passengers', getLanguageCode(this.props.location))
        }

        // TODO: error scrolling
    }
}
